<script lang="ts">
  import { onDestroy } from "svelte";
  import { goto } from "$app/navigation"
  // Utils
  import { Monitor } from "$lib/utils/monitor";
  // Stores
  import { GtmStore } from "$lib/utils/gtm-store";
  // Components
  import { Grid } from "$lib/components/organisms/Layout";
  import { Button } from "$lib/components/atoms/Button";
  // Contentful
	import type { IPromoBoxes } from "$lib/utils/contentful/types";
  
  export let data: IPromoBoxes;
  export let rounded = false;

  let promoElement: HTMLElement;
  let monitor: Monitor;
  let viewState: boolean = false;

  const onViewHandler = () => {
    if (!viewState) {
      GtmStore.pushHomepageBannerViewEvent(data.title);
      viewState = true;
    }
  };

  const onClickHandler = (link: string) => {
    GtmStore.pushEvent({
      event: "BBevent",
      event_id: "52",
      category: "BannerHP",
      action: "CLICK",
      label: data.title,
    });

    goto(link);
  };

  $: if (promoElement && !monitor) {
    monitor = new Monitor(promoElement, onViewHandler);
    monitor.startMonitoring();
  }

  onDestroy(() => {
    if (monitor) {
      monitor.stopMonitoring();
    }
  });
</script>

<section bind:this={promoElement} class="profiler-outer" class:rounded>
  <Grid noGrid>
    <div class="profiler">
      <div class="content">
        <p class="subhead">{data.label}</p>
        <h4 class="title">{data.title}</h4>
        <p class="paragraph">{data.content}</p>
      </div>
      {#if data.cta && data.cta.CTAActive}
        <div class="cta">
          <Button
            on:click={() => onClickHandler(data.cta.CTALink)}
            color="white"
          >
            {data.cta.CTAText}
          </Button>
        </div>
      {/if}
    </div>
  </Grid>
</section>

<style type="text/scss" lang="scss" scoped>
  .profiler-outer {
    @apply bg-petrol;
    @apply py-6;
    @apply md:py-10;
  }

  .profiler {
    @apply w-full;
    @apply flex flex-col justify-center;
    @apply md:flex-row md:justify-between;
  }

  .subhead {
    @apply text-white text-sm md:text-base text-opacity-50 font-semibold uppercase tracking-wide;
    @apply mb-2;
  }

  .title {
    @apply text-3xl font-serif md:text-4xl lg:text-6xl;
    @apply text-white;
    @apply mb-4;
  }

  .paragraph {
    @apply text-sm md:text-base;
    @apply text-white;
  }

  .content {
    @apply mb-6 md:mb-0 md:mr-14;
  }

  .cta {
    @apply flex-shrink-0 md:self-end;
  }
</style>
